import LANGUAGE from "constants/language.constants";

import locale from "locale";

class LocalizationHelper {
  constructor() {
    this.defaultLanguage = LANGUAGE.SERBIAN;
  }

  initializeLanguage() {
    const lang = localStorage.getItem("language") || this.defaultLanguage;
    locale.setLanguage(lang);
    return lang;
  }

  getLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  }

  setLanguage(lang) {
    localStorage.setItem("language", lang);
  }
}

const localizationHelper = new LocalizationHelper();

export default localizationHelper;
