export const GET_SEARCH_DESTINATIONS = "GET_SEARCH_DESTINATIONS";
export const SET_SEARCH_DESTINATIONS = "SET_SEARCH_DESTINATIONS";

export const GET_ROOT_DESTINATIONS = "GET_ROOT_DESTINATIONS";
export const SET_ROOT_DESTINATIONS = "SET_ROOT_DESTINATIONS";

export const GET_DESTINATION = "GET_DESTINATION";
export const SET_DESTINATION = "SET_DESTINATION";

export const SET_LOADING = "SET_LOADING";

export const GET_OFFER = "GET_OFFER";
export const SET_OFFER = "SET_OFFER";
