import { SET_OFFER, GET_OFFER } from "../actions/ActionTypes";

const initialState = {
  loading: false,
  loadedOffers: [],
  currentOffer: {}
};

const offersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER:
      return {
        ...state,
        loading: true
      };
    case SET_OFFER:
      return {
        currentOffer: action.payload,
        loading: false,
        loadedOffers: action.payload.exist
          ? [...state.loadedOffers]
          : [...state.loadedOffers, action.payload]
      };
    default:
      return state;
  }
};

export default offersReducer;
