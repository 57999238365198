import { call, put, select } from "redux-saga/effects";
import OfferService from "Services/OfferService";
import { setOffer, setLoading } from "Store/actions/OfferActions";

export function* getOffer({ payload }) {
  try {
    const { offers } = yield select();
    const offer = offers.loadedOffers.find((offer) => offer.name === payload);
    if (offer) {
      offer.exist = true;
      yield put(setOffer(offer));
    } else {
      const { data } = yield call(OfferService.getOffer, payload);
      yield put(setOffer(data));
    }
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}
