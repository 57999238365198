import { call, put } from "redux-saga/effects";
import SearchService from "Services/SearchService";
import { setDestinations } from "Store/actions/SearchActions";

export function* getDestinations() {
  try {
    const { data } = yield call(SearchService.getDestinations);
    yield put(setDestinations(data));
  } catch (error) {
    console.log(error);
  }
}
