import { GET_OFFER, SET_OFFER, SET_LOADING } from "./ActionTypes";

export const getOffer = (payload) => {
  return {
    type: GET_OFFER,
    payload
  };
};

export const setOffer = (payload) => {
  return {
    type: SET_OFFER,
    payload
  };
};

export const setLoading = (payload) => {
  return {
    type: SET_LOADING,
    payload
  };
};
