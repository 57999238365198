const ROUTES = {
  COMMON: {
    HOME: "/",
    CONTACT: "/kontakt",
    ABOUT: "/onama",
    DOCUMENTS: "/dokumenti",
    TRAVELS: "/putovanja",
    SUMMER_VACATION: "/putovanja/Letovanje",
    SERBIA_AND_REGION: "/putovanja/Srbija i Region",
    EUROPEAN_CITIES: "/putovanja/Evropski gradovi",
    TRIPS: "/putovanja/Dnevni izleti",
    SPA_AND_MOUNTAINS: "/putovanja/Banje i planine",
  },
  ENDPOINTS: {
    ROOT_DESTINATION: "/putovanja",
    DESTINATION: "/putovanja/%s",
    OFFER: "/lokacija/%s",
  },
  ASSETS: {
    PRICELIST: "/static/cenovnici/%s",
  },
};

export default ROUTES;
