import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import store, { history } from 'Store/Store';
import LocalizationHelper from 'Helpers/localization.helper';

import Routes from 'Routes/Routes';
import RouteChangeTracker from 'Routes/RouteChangeTracker';

import Navbar from 'Components/Layout/Navbar';
import Footer from 'Components/Layout/Footer';

function App() {
  const lang = LocalizationHelper.initializeLanguage();

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang }} />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Navbar />
          <Routes />
          <RouteChangeTracker />
          <Footer />
        </ConnectedRouter>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
