const localeSr = {
  COMMON: {
    BRAND: 'Mozaik021',
    TOURISTIC_AGENCY: 'Turistička agencija',
    MOZAIK: 'Mozaik',
    NUM_021: '021',
    MAIN_PHONE_NUMBER: '021-424-735',
    SECOND_PHONE_NUMBER: '021-455-500',
    CALL: 'Pozovi',
    LOOK: 'Pogledaj',
    SEND: 'Pošalji',
    INFO_EMAIL: 'info@mozaik021.rs',
  },
  ROUTES: {
    HOME: 'Početna',
    CONTACT: 'Kontakt',
    ABOUT: 'O nama',
    DOCUMENTS: 'Dokumenti',
  },
  TRAVELS: {
    TRAVELS: 'Putovanja',
    SUMMER_VACATION: 'Letovanje',
    SERBIA_AND_REGION: 'Srbija i region sa noćenjem',
    EUROPEAN_CITIES: 'Evropski gradovi',
    TRIPS: 'Dnevni izleti',
    LOCAL_TOURISM: 'Lokalni turizam',
    SUMMER_YEAR: 'Leto',
    SUMMER_DESCRIPTION_PART_1: 'Šta je za Vas savršen odmor?',
    SUMMER_DESCRIPTION_PART_2:
      'Za nas je to pesak, sunce, more, talasi i sve to uz neku dobru knjigu. Vreme je da spakujete kofere i odaberete savršeno i jeftino putovanje u kojem ćete uživati.',
    EUROPE: 'Evropa',
    EUROPE_DESCRIPTION_PART_1:
      'Da li ste se upoznali sa lepotama gradova Evrope?',
    EUROPE_DESCRIPTION_PART_2:
      'Mi smo tu kako bismo Vam pokazali šarm Londona, kulturu Praga, svetlost Pariza, lepote Toskane, arhitekturu Moskve. Posetite nas i zajedno sa nama doživite čari evropske kulture.',
    ONE_DAY_TRIPS: 'Jednodnevni izleti',
    TRIPS_ONLY: 'Izleti',
    TRIPS_DESCRIPTION:
      'Napravite predah od naporene nedelje i provedite vikend sa nama na nekom od naših izleta. Upoznajmo zajedno Srbiju, krstarimo Đerdapom, posetimo vojvođanske salaše, manastire, Uvac, Taru, udahnimo Zlatibor punim plućima...',
  },
  HOME: {
    SLOGAN:
      'Zaronite sa nama u svet magičnih putovanja, osetite nove mirise, ukuse i doživite čaroliju.',
  },
  SEARCH: {
    DEPARTURE_DATE: 'Datum polaska',
    ARRIVAL_DATE: 'Datum povratka',
    SEARCH: 'Pretraži',
    DESTINATION: 'Destinacija',
  },
  SERVICES: {
    OUR_SERVICES_QUESTION: 'Koje su naše usluge?',
    OUR_SERVICES_ANSWER:
      'Letovanja, zimovanja, grupna i individualna putovanja, viziranje, putno osiguranje...',
    TRAVELS: 'Putovanja',
    TRAVELS_DESCRIPTION:
      'Pobegnete daleko od svakodnevnice, a bliže pozitivnim mislima. Sklopite mozaik Vašeg idealnog putovanja i dozvolite da Vam predložimo savršen odmor za Vas.',
    VISAS_AND_AIRLINE_TICKETS: 'Vize i avio karte',
    VISAS_AND_AIRLINE_TICKETS_DESC_1:
      'U našim poslovnicama možete kupiti avio karte za bilo koju destinaciju po najpovoljnijim cenama. Takođe, radimo elektronsko popunjavanje formulara za BRITANSKU I AMERIČKU VIZU. Spisak dokumentacije potrebne za dobijanje britanske vize možete preuzeti ',
    VISAS_AND_AIRLINE_TICKETS_DESC_2: 'OVDE',
    VISAS_AND_AIRLINE_TICKETS_DESC_3:
      ', a za sve ostale informacije obratiti se u našim poslovnicama.',
    TRAVEL_INSURANCE: 'Putno osiguranje',
    TRAVEL_INSURANCE_DESCRIPTION:
      'U našoj agenciji možete kupiti putno osiguranje za Vas i Vašu porodicu za ceo svet.',
  },
  TESTIMONIALS: {
    QUESTION: 'Šta ljudi misle o nama',
    ANSWER:
      'Neki od naših zadovoljnih putnika su podelili iskustvo sa našom agencijom',
    PERSON_1_NAME: 'Ana Stojanović',
    PERSON_1_QUOTE:
      'Pouzdani. Putujem već godinama i do sada je uvek bilo onako kako su rekli.',
    PERSON_2_NAME: 'Jelena Pavlović',
    PERSON_2_QUOTE:
      'Putovala sam već dvaput sa njima u London. Morala sam ponoviti ovo nezaboravno putovanje.',
    PERSON_3_NAME: 'Vesna Karamatić',
    PERSON_3_QUOTE:
      'Godinama putujem u istu vilu na Eviji i ne planiram da menjam ni destinaciju, ni agenciju. Zadovoljna u potpunosti.',
  },
  NEWSLETTER: {
    NEWSLETTER: 'Newsletter',
    DESCRIPTION:
      'Budite prvi obavešteni o najnovijim ponudama i popustima. Iskoristite priliku da putujete po najpovoljnijim cenama.',
    EMAIL_PLACEHOLDER: 'Vaš email',
    SUCCESS_MESSAGE: 'Uspesno ste dodati na mejling listu',
  },
  FOOTER: {
    AGENCY: 'Turisticka agencija Mozaik021',
    WORKING_HOURS: 'Radno vreme',
    WORKING_HOURS_DAYS: 'Pon-Pet: 9h - 19h',
    WORKING_HOURS_WEEKEND: 'Sub: 9h - 13h',
    WORKING_HOURS_SUNDAY: 'Nedelja neradna',
    LINKS: 'Linkovi',
  },
  LICENCE: {
    LICENCE: 'Licenca',
    LICENCE_NUMBER: 'PTP, registar turizma BTP 36/2021',
  },
  COPYRIGHT: {
    COPYRIGHT: 'Copyright: Mozaik021.rs',
  },
  HELMET: {
    ABOUT_PAGE_TITLE: 'O nama | Turistička agencija Mozaik 021',
    CONTACT_PAGE_TITLE: 'Kontakt | Turistička agencija Mozaik 021',
    DOCUMENTS_PAGE_TITLE: 'Dokumenti | Turistička agencija Mozaik 021',
    BASIC_PAGE_TITLE: 'Turistička agencija Mozaik 021',
  },
  ABOUT: {
    ABOUT: 'O nama',
    HERO_DESCRIPTION:
      'Turistička agencija Mozaik 021 bavi se organizacijom turističkih putovanja u zemlji i inostranstvu, prodajom avio karata, putnog zdravstvenog osiguranja i uslugama viziranja.',
    YEARS_IN_BUSSINESS: 'Godina poslovanja',
    TRAVELERS: 'Putnika',
    ORGANIZED_TRAVELS: 'Organizovanih putovanja',
    ABOUT_US_TITLE: 'Ukratko o nama',
    ABOUT_US_LONG_DESCRIPTION:
      'Turistička agencija Mozaik 021 je osnovana 02.04.2008.godine u Novom Sadu, fokusirana prvenstveno na putovanja po atraktivnim evropskim gradovima. Veliki broj putnika nas prepoznaje po već tradicionalnom putovanju u London. Kasnije smo proširili delatnost na zakup smeštajnih jedinica u Grčkoj u vrlo atraktivnim letovalištima. I dalje obogaćujemo našu ponudu apartmana birajući pažljivo, domaćinski. Poslednjih godina smo fokus naših putovanja usmerili ka dnevnim izletima u Srbiji i okolini poput Đerdapa, Uvca, Oplenaca, Temišvara, Pečuja...I tu nije kraj. Posetite ponovo naš sajt i naćićete uvek nešto novo i zanimljivo.',
  },
  CONTACT: {
    CONTACT: 'Kontakt',
    DESCRIPTION:
      'Karakteristika osoblja turističke agencije Mozaik021 je visoka stručnost i upućenost u sve segmente turizma, tako da možete biti sigurni da ćete na sva Vaša pitanja u vezi putovanja i sve Vaše nedoumice oko pravog izbora destinacije za Vas dobiti odgovor kod nas. Kontaktirajte nas putem telefona, email-a ili kontakt forme.',
    WRITE_TO_US: 'Pišite nam',
    SUBJECT_VALID_FEEDBACK: 'Tema nije obavezna',
    EMAIL_INVALID_FEEDBACK: 'Molimo Vas popunite polje za email adresu',
    NAME_INVALID_FEEDBACK: 'Molimo Vas unesite svoje ime',
    MESSAGE_INVALID_FEEDBACK: 'Molimo Vas unesite željenu poruku',
    MESSAGE_SENT: 'Poruka je poslata',
    ADDRESS: 'Bulevar cara Lazara 19',
    CITY: 'Novi Sad',
    YOUR_NAME: 'Vaše ime',
    YOUR_EMAIL: 'Vaš email',
    SUBJECT: 'Tema',
    MESSAGE: 'Poruka',
  },
  DOCUMENTS: {
    DOCUMENTS: 'Dokumenti',
    DOCUMENT_1: 'Opšti uslovi putovanja',
    DOCUMENT_2: 'Ugovor o subagentaturi',
    DOCUMENT_3: 'Osnovni podaci o agenciji',
    DOCUMENT_4:
      'Spisak dokumentacije potrebne za dobijanje vize Velike Britanije',
    DOCUMENT_5: 'Polisa',
    DOCUMENT_6: 'Rešenje iz APR-a',
    DOWNLOAD_PRICELIST_PART_1: 'Kliknite',
    DOWNLOAD_PRICELIST_PART_2: 'ovde',
    DOWNLOAD_PRICELIST_PART_3: 'da biste preuzeli cenovnik.',
  },
  ERROR: {
    FAILED_TO_SEND: 'Poruka nije poslata, pokušajte ponovo',
  },
  OFFER: {
    DESCRIPTION: 'Opis:',
    TRAVEL_PROGRAM: 'Program putovanja:',
    LOCATION: 'Lokacija:',
    PRICE_PER_PERSON: 'Cene sa prevozom po osobi',
    NUMBER_OF_NIGHTS: 'Broj noćenja',
    PER_PERSON: 'po osobi',
  },
  NOT_FOUND: {
    PAGE_NOT_FOUND: 'Stranica nije pronađena',
    LOOK_FOR_TRAVELS: 'Pogledaj putovanja',
  },
};

export default localeSr;
