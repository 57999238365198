import {
  GET_SEARCH_DESTINATIONS,
  SET_SEARCH_DESTINATIONS
} from "./ActionTypes";

export const getDestinations = () => {
  return {
    type: GET_SEARCH_DESTINATIONS
  };
};

export const setDestinations = (payload) => {
  return {
    type: SET_SEARCH_DESTINATIONS,
    payload
  };
};
