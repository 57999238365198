import { MDBNavItem, MDBNavLink } from "mdbreact";

export default function NavbarLink({ isActive, routeTo, label }) {
  return (
    <MDBNavItem active={isActive} className="moz-l-navbar__item">
      <MDBNavLink to={routeTo} className="moz-l-navbar__item-link">
        {label}
      </MDBNavLink>
    </MDBNavItem>
  );
}
