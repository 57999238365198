import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-221009746-1';
ReactGA.initialize(TRACKING_ID);

const RouteChangeTracker = ({ location }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location.pathname]);

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
