import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import RootDestination from "Components/Pages/Destination/RootDestination";
import Destination from "Components/Pages/Destination/Destination";

const Home = lazy(() => import("Components/Pages/Home/Home.js"));
const About = lazy(() => import("Components/Pages/About/About.js"));
const Contact = lazy(() => import("Components/Pages/Contact/Contact.js"));
const Documents = lazy(() => import("Components/Pages/Documents/Documents.js"));
const Offer = lazy(() => import("Components/Pages/Offer/Offer"));
const NotFound = lazy(() => import("Components/Pages/NotFound/NotFound"));

export default function Routes() {
  return (
    <Suspense
      fallback={
        <div className="moz-l-progress moz-l-progress--full-screen">
          <CircularProgress />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kontakt" component={Contact} />
        <Route exact path="/onama" component={About} />
        <Route exact path="/dokumenti" component={Documents} />
        <Route exact path="/putovanja" component={RootDestination} />
        <Route exact path="/putovanja/:name" component={Destination} />
        <Route
          exact
          path="/lokacija/:name"
          render={(props) => (
            <Offer name={props.match.params.name} {...props} />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
