import {
  SET_ROOT_DESTINATIONS,
  GET_ROOT_DESTINATIONS,
  GET_DESTINATION,
  SET_DESTINATION,
  SET_LOADING
} from "../actions/ActionTypes";

const initialState = {
  loading: false,
  currentDestination: {},
  rootDestinations: [],
  loadedDestinations: []
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROOT_DESTINATIONS:
      return { ...state, loading: true };
    case SET_ROOT_DESTINATIONS:
      return { ...state, rootDestinations: action.payload, loading: false };
    case GET_DESTINATION:
      return { ...state, currentDestination: {}, loading: true };
    case SET_DESTINATION:
      return {
        ...state,
        loadedDestinations: action.payload.exist
          ? [...state.loadedDestinations]
          : [...state.loadedDestinations, action.payload],
        currentDestination: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};

export default searchReducer;
