import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  offer: "/api/destination/%s"
};

class OfferService extends ApiService {
  getOffer = (name) => this.apiClient.get(format(ENDPOINTS.offer, name));
}

const offerService = new OfferService();

export default offerService;
