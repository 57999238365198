import { useEffect } from "react";
import { connect } from "react-redux";
import { MDBMask, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";

import Search from "Components/Search/Search";
import TravelCard from "Components/Card/TravelCard";
import { getRootDestinations } from "Store/actions/DestinationActions";
import locale from "locale";

const RootDestination = ({ destinations, loading, getRootDestinations }) => {
  useEffect(() => {
    if (destinations.length === 0) getRootDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="moz-p-root-dest">
      <div className="moz-p-root-dest__header">
        <MDBMask
          overlay="black-light"
          className="moz-p-root-dest__header-wrapper"
        >
          <MDBContainer className="moz-p-root-dest__header-content">
            <h1 className="heading-primary">{locale.TRAVELS.TRAVELS}</h1>
            <hr className="thick-hr" />
          </MDBContainer>
        </MDBMask>
      </div>

      <MDBContainer className="mb-5">
        <Search home />

        <MDBRow>
          {loading ? (
            <div className="moz-l-progress--universal">
              <CircularProgress />
            </div>
          ) : (
            destinations.map((destination) => (
              <MDBCol key={destination._id} md="6" lg="4" className="my-3">
                <TravelCard
                  name={destination.name}
                  desc={destination.descNode}
                  image={destination.image}
                />
              </MDBCol>
            ))
          )}
        </MDBRow>
      </MDBContainer>
    </main>
  );
};

const mapStateToProps = (state) => ({
  loading: state.destinations.loading,
  destinations: state.destinations.rootDestinations
});

export default connect(mapStateToProps, { getRootDestinations })(
  RootDestination
);
