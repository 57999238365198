import {
  GET_ROOT_DESTINATIONS,
  SET_ROOT_DESTINATIONS,
  GET_DESTINATION,
  SET_DESTINATION,
  SET_LOADING
} from "./ActionTypes";

export const getRootDestinations = () => {
  return {
    type: GET_ROOT_DESTINATIONS
  };
};

export const setRootDestinations = (payload) => {
  return {
    type: SET_ROOT_DESTINATIONS,
    payload
  };
};

export const getDestination = (payload) => {
  return {
    type: GET_DESTINATION,
    payload
  };
};

export const setDestination = (payload) => {
  return {
    type: SET_DESTINATION,
    payload
  };
};

export const setLoading = (payload) => {
  return {
    type: SET_LOADING,
    payload
  };
};
