import ApiService from "./ApiService";

const ENDPOIINTS = {
  DESTINATIONS: "/api/location/allNodes"
};

class SearchService extends ApiService {
  getDestinations = () => {
    return this.apiClient.get(ENDPOIINTS.DESTINATIONS);
  };
}

const searchService = new SearchService();
export default searchService;
