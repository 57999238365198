import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import locale from "locale";

import logoNav from "Styles/img/logo/logoNav.png";
import ROUTES from "Constants/routes.constants";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
} from "mdbreact";

import NavbarLink from "./NavbarLink";
import NavbarDropdown from "./NavbarDropdown";

function Navbar({ location, history }) {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    setCollapse(false);
  }, [location.pathname]);

  const toggleCollapse = () => {
    setCollapse((c) => !c);
  };

  const checkIsActiveRoute = (route) => location.pathname === route;

  return (
    <header>
      <MDBNavbar
        color="bg-primary"
        fixed="top"
        dark
        expand="md"
        scrolling
        transparent={!collapse}
        className="moz-l-navbar"
      >
        <MDBContainer>
          <MDBNavbarBrand
            href={ROUTES.COMMON.HOME}
            className="moz-l-navbar__brand"
          >
            <img
              className="moz-l-navbar__brand-image"
              src={logoNav}
              alt={locale.COMMON.BRAND + " logo"}
            />
            <span className="moz-l-navbar__brand-name">
              {locale.COMMON.BRAND}
            </span>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={toggleCollapse} />
          <MDBCollapse isOpen={collapse} navbar>
            <MDBNavbarNav right>
              <NavbarLink
                isActive={checkIsActiveRoute(ROUTES.COMMON.HOME)}
                routeTo={ROUTES.COMMON.HOME}
                label={locale.ROUTES.HOME}
              />
              <NavbarDropdown routeTo={(route) => history.push(route)} />
              <NavbarLink
                isActive={checkIsActiveRoute(ROUTES.COMMON.CONTACT)}
                routeTo={ROUTES.COMMON.CONTACT}
                label={locale.ROUTES.CONTACT}
              />
              <NavbarLink
                isActive={checkIsActiveRoute(ROUTES.COMMON.ABOUT)}
                routeTo={ROUTES.COMMON.ABOUT}
                label={locale.ROUTES.ABOUT}
              />
              <NavbarLink
                isActive={checkIsActiveRoute(ROUTES.COMMON.DOCUMENTS)}
                routeTo={ROUTES.COMMON.DOCUMENTS}
                label={locale.ROUTES.DOCUMENTS}
              />
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </header>
  );
}

export default withRouter(Navbar);
