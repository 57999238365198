// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import "isomorphic-fetch";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import locale from "locale";

import { getDestinations } from "Store/actions/SearchActions";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const ComboBox = ({
  value,
  handleChange,
  options,
  loaded,
  getDestinations
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loaded) return;
    if (open) getDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      freeSolo
      getOptionLabel={(option) => option.name}
      options={options}
      loading={!loaded && open}
      onChange={(e, changed) => handleChange(e, changed)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={locale.SEARCH.DESTINATION}
          fullWidth
          variant="outlined"
          onChange={(e) => handleChange(e, null)}
          value={value}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!loaded && open ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state.search.destinations,
    loaded: state.search.loaded
  };
};

const mapDispatchToProps = {
  getDestinations
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboBox);
