import { call, put, select } from "redux-saga/effects";
import DestinationService from "Services/DestinationService";
import {
  setRootDestinations,
  setDestination,
  setLoading
} from "Store/actions/DestinationActions";

export function* getRootDestinations() {
  try {
    const { data } = yield call(DestinationService.getRootDestinations);
    yield put(setRootDestinations(data));
  } catch (error) {
    console.log(error);
  }
}

export function* getDestination({ payload }) {
  try {
    const { destinations } = yield select();
    const destination = destinations.loadedDestinations.find(
      (destination) => destination.name === payload
    );
    if (destination) {
      destination.exist = true;
      yield put(setDestination(destination));
    } else {
      const { data } = yield call(DestinationService.getDestination, payload);
      yield put(setDestination(data));
    }
  } catch (error) {
    yield put(setLoading(false));
    console.log(error);
  }
}
