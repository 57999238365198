import {
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import axios from "axios";
import config from "../../config";
import locale from "locale";
import { useEffect } from "react";
import { useState } from "react";
import ROUTES from "Constants/routes.constants";

const BASE_NAV_ENPOINT = "/api/location";

export default function NavbarDropdown({ routeTo }) {
  const [rootRoutes, setRootRoutes] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(config.API_BASE_URL + BASE_NAV_ENPOINT);
      setRootRoutes(data.map(({ name }) => name));
    })();
  }, []);
  return (
    <MDBNavItem className="moz-l-navbar__dropdown-item">
      <MDBDropdown>
        <MDBDropdownToggle nav caret className="moz-l-navbar__dropdown-title">
          <span className="mr-1">{locale.TRAVELS.TRAVELS}</span>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="moz-l-navbar__dropdown">
          {rootRoutes.map((route) => (
            <MDBDropdownItem onClick={() => routeTo(`/putovanja/${route}`)}>
              {route}
            </MDBDropdownItem>
          ))}
          <MDBDropdownItem
            onClick={() => routeTo(ROUTES.COMMON.SERBIA_AND_REGION)}
          >
            {locale.TRAVELS.SERBIA_AND_REGION}
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    </MDBNavItem>
  );
}
