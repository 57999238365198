import { Link } from "react-router-dom";

import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

import locale from "locale";
import ROUTES from "Constants/routes.constants";

const Footer = () => {
  return (
    <MDBFooter className="moz-l-footer">
      <MDBContainer className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="12">
            <h5 className="heading-tertiary pb-3">{locale.FOOTER.AGENCY}</h5>
          </MDBCol>
          <MDBCol md="6">
            <div>
              <h5 className="heading-small">{locale.FOOTER.WORKING_HOURS}</h5>
              <p style={{ marginBottom: 0 }} className="paragraph--small">
                {locale.FOOTER.WORKING_HOURS_DAYS}
              </p>
              <p style={{ marginBottom: 0 }} className="paragraph--small">
                {locale.FOOTER.WORKING_HOURS_WEEKEND}
              </p>
              <p className="paragraph--small">
                {locale.FOOTER.WORKING_HOURS_SUNDAY}
              </p>
            </div>
          </MDBCol>
          <MDBCol md="6">
            <h5 className="heading-small">{locale.FOOTER.LINKS}</h5>

            <li className="list-unstyled">
              <Link
                to={ROUTES.COMMON.SUMMER_VACATION}
                className="paragraph--small"
              >
                {locale.TRAVELS.SUMMER_VACATION}
              </Link>
            </li>
            <li className="list-unstyled">
              <Link
                to={ROUTES.COMMON.EUROPEAN_CITIES}
                className="paragraph--small"
              >
                {locale.TRAVELS.EUROPEAN_CITIES}
              </Link>
            </li>
            <li className="list-unstyled">
              <Link to={ROUTES.COMMON.TRIPS} className="paragraph--small">
                {locale.TRAVELS.TRIPS}
              </Link>
            </li>
            <p></p>
          </MDBCol>
          <MDBCol md="6" className="pt-md-3">
            <h5 className="heading-small">{locale.LICENCE.LICENCE}</h5>
            <p className="paragraph--small">{locale.LICENCE.LICENCE_NUMBER}</p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3 mt-2 paragraph--small">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} {locale.COPYRIGHT.COPYRIGHT}
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
