import { useState } from "react";
import { withRouter } from "react-router";
import { format } from "util";
import locale from "locale";
import ROUTES from "Constants/routes.constants";

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SearchBox from "./ComboBox";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

const Search = ({ history, home }) => {
  const [selectedDate, setSelectedDate] = useState([null, null]);

  const [value, setValue] = useState("");

  const handleChange = (e, changed) => {
    if (changed) {
      setValue(changed.name);
    } else if (e.target.value && !!e.target.value.length) {
      setValue(e.target.value);
    } else {
      setValue("");
    }
  };

  const handleSubmit = () => {
    history.push(format(ROUTES.ENDPOINTS.DESTINATION, value));
  };

  return (
    <MDBCard
      style={{ transform: home && "translateY(-40px)" }}
      className="moz-c-search"
    >
      <MDBCardBody>
        <MDBRow>
          <MDBCol lg="4" md="12" style={{ paddingTop: 12 }}>
            <SearchBox value={value} handleChange={handleChange} />
          </MDBCol>
          <MDBCol lg="3" md="6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                margin="normal"
                id="date-picker-dialog-start"
                label={locale.SEARCH.DEPARTURE_DATE}
                className="w-100"
                clearable
                autoOk
                format="dd/MM/yyyy"
                value={selectedDate[0]}
                onChange={(date) => setSelectedDate([date, selectedDate[1]])}
                aria-label="change date"
              />
            </MuiPickersUtilsProvider>
          </MDBCol>
          <MDBCol lg="3" md="6">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                margin="normal"
                id="date-picker-dialog-end"
                label={locale.SEARCH.ARRIVAL_DATE}
                className="w-100"
                clearable
                autoOk
                format="dd/MM/yyyy"
                value={selectedDate[1]}
                onChange={(date) => setSelectedDate([selectedDate[0], date])}
                aria-label="change date"
              />
            </MuiPickersUtilsProvider>
          </MDBCol>
          <MDBCol
            lg="2"
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <MDBBtn color="primary" onClick={handleSubmit}>
              {locale.SEARCH.SEARCH}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default withRouter(Search);
