import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  rootDestinations: "/api/location",
  destination: "/api/location/%s"
};

class DestinationService extends ApiService {
  getRootDestinations = () => {
    return this.apiClient.get(ENDPOINTS.rootDestinations);
  };

  getDestination = (name) => {
    return this.apiClient.get(format(ENDPOINTS.destination, name));
  };
}

const destinationService = new DestinationService();

export default destinationService;
