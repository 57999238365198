/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import searchReducer from "./SearchReducer";
import destinationsReducer from "./DestinationsReducer";
import offersReducer from "./OffersReducer";

export default (history) =>
  combineReducers({
    destinations: destinationsReducer,
    offers: offersReducer,
    search: searchReducer,
    router: connectRouter(history)
  });
