import ROUTES from "constants/routes.constants";
import locale from "locale";

export default function NotFound({ history }) {
  const routeToTravels = () => history.push(ROUTES.COMMON.TRAVELS);
  return (
    <main className="moz-p-not-found ">
      <h1 className="heading-primary">{locale.NOT_FOUND.PAGE_NOT_FOUND}</h1>
      <button className="btn btn-outline-white" onClick={routeToTravels}>
        {locale.NOT_FOUND.LOOK_FOR_TRAVELS}
      </button>
    </main>
  );
}
