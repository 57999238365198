import { all, takeLatest } from "redux-saga/effects";

import {
  GET_DESTINATION,
  GET_OFFER,
  GET_ROOT_DESTINATIONS,
  GET_SEARCH_DESTINATIONS
} from "../actions/ActionTypes";

import { getDestination, getRootDestinations } from "./DestinationsSagas";
import { getOffer } from "./OffersSagas";
import { getDestinations } from "./SearchSagas";

export default function* rootSaga() {
  yield all([
    takeLatest(GET_ROOT_DESTINATIONS, getRootDestinations),
    takeLatest(GET_DESTINATION, getDestination),
    takeLatest(GET_SEARCH_DESTINATIONS, getDestinations),
    takeLatest(GET_OFFER, getOffer)
  ]);
}
