import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import { Link } from "react-router-dom";
import { format } from "util";

import ROUTES from "constants/routes.constants";
import locale from "locale";

export default function Breadcrumb({ destination, name }) {
  return (
    <MDBBreadcrumb>
      <MDBBreadcrumbItem>
        <Link
          to={ROUTES.ENDPOINTS.ROOT_DESTINATION}
          className="moz-l-breadcrumb__link"
        >
          {locale.TRAVELS.TRAVELS}
        </Link>
      </MDBBreadcrumbItem>
      {destination &&
        destination.parent.map((parent) => (
          <MDBBreadcrumbItem key={parent}>
            <Link
              to={format(ROUTES.ENDPOINTS.DESTINATION, parent)}
              className="moz-l-breadcrumb__link"
            >
              {parent}
            </Link>
          </MDBBreadcrumbItem>
        ))}
      <MDBBreadcrumbItem active>{name}</MDBBreadcrumbItem>
    </MDBBreadcrumb>
  );
}
