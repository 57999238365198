import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import { format } from "util";

import ROUTES from "Constants/routes.constants";

import locale from "locale";

export default function Pricelist({ downloadUrl }) {
  return (
    <Paper elevation={3} className="moz-c-pricelist">
      <a
        style={{ color: "black", textDecoration: "none" }}
        href={format(ROUTES.ASSETS.PRICELIST, downloadUrl)}
        download
      >
        <div className="moz-c-pricelist__content">
          <IconButton>
            <GetAppIcon fontSize="large" />
          </IconButton>
          <p className="moz-c-pricelist__content-text">
            {locale.DOCUMENTS.DOWNLOAD_PRICELIST_PART_1}&nbsp;
            <span style={{ color: "#82b1ff", textDecoration: "underline" }}>
              {locale.DOCUMENTS.DOWNLOAD_PRICELIST_PART_2}
            </span>
            &nbsp;{locale.DOCUMENTS.DOWNLOAD_PRICELIST_PART_3}
          </p>
        </div>
      </a>
    </Paper>
  );
}
