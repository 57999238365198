import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBBtn,
  MDBCardBody,
  MDBCardImage,
  MDBCardText
} from "mdbreact";
import { format } from "util";

import locale from "locale";
import ROUTES from "Constants/routes.constants";

export default function PutovanjaItem({ name, desc, image, leaf }) {
  const handleRoute = () =>
    leaf
      ? format(ROUTES.ENDPOINTS.OFFER, name)
      : format(ROUTES.ENDPOINTS.DESTINATION, name);

  const formatDescription = () =>
    desc && desc.length > 75 ? `${desc.slice(0, 75)}...` : desc;

  return (
    <MDBCard className="moz-c-travel-card">
      <Link to={handleRoute}>
        <MDBCardImage className="moz-c-travel-card__image" src={image} waves />
      </Link>
      <MDBCardBody className="moz-c-travel-card__body">
        <Link to={handleRoute}>
          <h2 className="heading-tertiary">{name}</h2>
        </Link>
        <MDBCardText className="paragraph">{formatDescription()}</MDBCardText>
        <Link to={handleRoute} className="moz-c-travel-card__button">
          <MDBBtn color="primary">{locale.COMMON.LOOK}</MDBBtn>
        </Link>
      </MDBCardBody>
    </MDBCard>
  );
}
