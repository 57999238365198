import { useEffect } from "react";
import { connect } from "react-redux";
import { MDBMask, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Helmet } from "react-helmet-async";
import CircularProgress from "@material-ui/core/CircularProgress";

import Breadcrumb from "Components/Breadcrumb/Breadcrumb";
import TravelCard from "Components/Card/TravelCard";
import NotFound from "Components/Pages/NotFound/NotFound";
import Pricelist from "Components/Pages/Destination/Pricelist";
import { getDestination } from "Store/actions/DestinationActions";
import locale from "locale";

const Destination = ({
  match: {
    params: { name }
  },
  destination,
  getDestination,
  loading
}) => {
  useEffect(() => {
    getDestination(name);
    //eslint-disable-next-line
  }, [name]);

  const destinationExists = () => Object.keys(destination).length > 0;

  if (loading) {
    return (
      <div className="moz-l-progress--full-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <main className="moz-p-destination">
      <Helmet>
        <title>
          {destination
            ? destination.name + " " + locale.HELMET.BASIC_PAGE_TITLE
            : locale.HELMET.BASIC_PAGE_TITLE}
        </title>
      </Helmet>
      {destinationExists() ? (
        <>
          {destination.iframe ? (
            <div
              style={{
                paddingTop: 86,
                background:
                  "linear-gradient(180deg, #092758 0%, rgb(255,255,255) 100%)"
              }}
            >
              <MDBContainer>
                <Breadcrumb destination={destination} name={name} />
              </MDBContainer>
              <iframe
                src={destination.iframe}
                frameBorder="0"
                style={{
                  border: 0,
                  zIndex: 100,
                  minHeight: "100vh",
                  width: "100%"
                }}
                height="100%"
                width="100%"
              >
                Your browser doesn't support iframes
              </iframe>
            </div>
          ) : (
            <div className="moz-p-destination__header">
              <MDBMask overlay="black-light">
                <MDBContainer className="moz-p-destination__header-content">
                  <h1 className="heading-primary">{name}</h1>
                  <hr className="thick-hr" />
                  {destination && !!destination?.description?.length && (
                    <p className="w-responsive mx-auto paragraph--lead">
                      {destination.description}
                    </p>
                  )}
                </MDBContainer>
              </MDBMask>
            </div>
          )}

          <MDBContainer className="mt-4 moz-u-margin-bottom-big">
            {!destination.iframe && (
              <Breadcrumb destination={destination} name={name} />
            )}
            <MDBRow>
              <>
                {destination.pricelistInPdf && (
                  <Pricelist downloadUrl={destination.pricelistInPdf} />
                )}

                {!destination.iframe &&
                  destination.nodeChildren.map(
                    (node) =>
                      node.active && (
                        <MDBCol key={node._id} md="6" lg="4" className="my-3">
                          <TravelCard
                            name={node.name}
                            desc={node.descNode}
                            image={node.image}
                          />
                        </MDBCol>
                      )
                  )}
                {destination.leafChildren.map(
                  (node) =>
                    node.active && (
                      <MDBCol key={node._id} md="6" lg="4" className="my-3">
                        <TravelCard
                          name={node.name}
                          desc={node.descNode}
                          image={node.image}
                          leaf
                        />
                      </MDBCol>
                    )
                )}
              </>
            </MDBRow>
          </MDBContainer>
        </>
      ) : (
        <NotFound />
      )}
    </main>
  );
};

const mapStateToProps = (state) => ({
  destination: state.destinations.currentDestination,
  loading: state.destinations.loading
});

export default connect(mapStateToProps, { getDestination })(Destination);
